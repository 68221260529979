.modal {
    .modal-header {
        box-shadow: 0 0 15px transparentize(black, .35);
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        z-index: 20;
    }

    .modal-body {
        padding-top: 77px;
        position: relative;
        z-index: 10;
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: $primary $body-bg;

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        background: $body-bg;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
        border: 4px solid $body-bg;
    }
}
