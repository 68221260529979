$border-width: 2px;
$border: dashed transparentize(#0d6efd, .5);
$indent: 60px;
$el-height: 24px;
$margin: 8px;
$max-rows: 12;
$max-height: #{($el-height * $max-rows) + ($margin * ($max-rows - 1))};
$transition-stack: all .25s ease;

.treeview-input {
    .search-engine {
        input {
            border-bottom-left-radius: 0;
        }

        button {
            border-bottom-right-radius: 0;
        }
    }
}

.search-engine + .treeview {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.treeview {
    margin: 0;
    border: 1px solid $secondary;
    border-radius: 3px;
    padding: 15px;
    background-color: $gray-800;

    .wrapper {
        & > ul:first-child::before {
            top: #{$el-height / 2 + $border-width};
        }
    }

    &:not(.treeview__listmode) {
        max-height: $max-height;
        overflow-y: scroll;
    }

    ul {
        padding: 0;
        list-style: none;
        position: relative;
        margin: 0 0 $margin;

        li {
            margin: 0;
            line-height: $el-height;
            position: relative;
            display: block;

            border-top: 1px dashed transparent;
            border-bottom: 1px dashed transparent;

            .treeview__level {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                margin-bottom: $margin;
                height: $el-height;
                position: relative;

                &.treeview__hover:not(.treeview__level0) {
                    &::before {
                        content: "";
                        display: block;
                        height: 0;
                        border-top: $primary $border;
                        border-color: $primary;
                        position: absolute;
                        top: #{$el-height / 2};
                        left: $indent;
                        right: $margin;
                        opacity: 0;
                        transition: $transition-stack;
                    }

                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                input {
                    display: none;
                }

                input:checked + .treeview__category {
                    border: 1px solid $primary;

                    .treeview__checkmark {
                        background-color: $primary;
                        box-shadow: 0 0 0 3px $gray-900, 0 0 0 4px $white;
                    }
                }

                .treeview__category {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0 1em;
                    border-radius: 3px;
                    border: 1px solid $gray-900;
                    background-color: $gray-900;
                    color: $gray-100;
                    user-select: none;
                    position: relative;
                    z-index: 1;
                    transition: $transition-stack;

                    & > * {
                        margin-right: .5em;
                    }

                    &.disabled {
                        pointer-events: none;

                        .treeview__checkmark {
                            opacity: 0 !important;
                        }
                    }

                    .treeview__checkmark {
                        $size: 8px;
                        display: inline-block;
                        width: $size;
                        height: $size;
                        border-radius: 999px;
                        box-shadow: 0 0 0 3px $gray-900, 0 0 0 4px transparentize($white, .5);
                        background-color: $gray-800;
                        transition: $transition-stack;
                        margin: 4px 12px 4px 4px;
                    }

                    label {
                        cursor: pointer;
                        transition: $transition-stack;

                        &::before {
                            content: "";
                            position: absolute;
                            display: block;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                        }
                    }
                }

                .treeview__actions {
                }
            }
        }

        ul {
            margin-left: $margin;

            &::before {
                content: "";
                display: block;
                width: 0;
                position: absolute;
                top: -$margin;
                bottom: 0;
                left: 0;
                border-left: $border-width $border;
            }

            li {
                padding: 0 0 0 $indent;

                &::before {
                    content: "";
                    display: block;
                    width: $indent;
                    height: 0;
                    border-top: $border-width $border;
                    position: absolute;
                    top: #{$el-height / 2};
                    left: 0;
                }

                &:last-child::before {
                    background: $gray-800;
                    height: auto;
                    bottom: 0;
                }
            }
        }
    }
}
