.file-cropper {
    .file-selector {

        & > * {
            padding: 7.5px;
        }

        .file-item {
            border: 2px solid transparent;
            text-align: center;
            border-radius: 3px;
            padding: 5px;
            cursor: pointer;
            user-select: none;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: all .25s ease;

            img {
                aspect-ratio: 4 / 3;
                width: 100%;
                object-fit: cover;
            }
            span {
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                display: -webkit-box !important;
                -webkit-box-orient: vertical;
                height: 3rem;
                font-size: .75rem;
                line-height: .75rem;
                margin-top: 0.25rem;
            }

            @each $color, $value in $theme-colors {
                &.file-item-#{$color} {
                    &:hover:not(.checked) {
                        box-shadow: 0 3px 8px 4px transparentize(black, .75);
                    }

                    &.checked {
                        background-color: transparentize($value, .75);
                    }
                }
            }
        }
    }

    .image-crop {
        $height: 380px;
        max-height: $height;

        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
