$tag-bg: $gray-900;
$tag-hover: mix($gray-800, $gray-900, 50%);
$tag-text-color: $gray-100;
$tag-remove: $danger;

@import '~@yaireo/tagify/src/tagify';

:root {
    --tagify-dd-color-primary: $primary; // should be same as "$tags-focus-border-color"
    --tagify-dd-bg-color: #1E293B;
}

.tagify-custom {

}

.tagify-custom__dropdown {
    .tagify__dropdown__wrapper {
        border: 1px solid $gray-900 !important;
        border-radius: 3px !important;
        max-height: 240px;

        .tagify__dropdown__item {
            color: $white;
            transition: $transition-stack;

            &:hover {
                color: $primary;
            }
        }
    }
}
