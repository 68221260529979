.jv-dark-con {
    background-color: #282c34;
    border-radius: 2px;
    border: 1px solid #282c34;

    .jv-folder {
        color: $white;
    }

    .jv-dark-left:not(.jv-folder) {
        color: $orange;
    }

    .jv-jv-dark--symbol {
        color: $white;
    }

    .jv-dark-rightString {
        color: $cyan;
    }
}
