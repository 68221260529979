$border-radius: .2rem;

.ql-rte {
    .ql-toolbar.ql-snow {
        background-color: #1e293b;
        border: 1px solid #484e5d;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    .ql-container.ql-snow {
        background-color: #1e293b;
        border: 1px solid #484e5d;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .ql-snow {
        .ql-picker {
            color: $white;
        }

        .ql-stroke {
            stroke: $white;
        }

        .ql-fill {
            fill: $white;
        }
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
        border-color: transparent;

        .ql-picker {
            color: $primary;
        }

        .ql-stroke {
            stroke: $primary;
        }

        .ql-fill {
            fill: $primary;
        }
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        background-color: #1e293b;
        border: 1px solid #484e5d;
    }

    .ql-editor {
        min-height: 280px;

        p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
            margin-bottom: 1rem;
        }
    }
}